module services {
    export module customs {
        export class goodsDeclarationService implements interfaces.customs.IGoodsDeclarationService {
            static $inject = ["$resource", "ENV","$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            generateGoodsDec(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/GenerateGoodsDeclarationEntries", {
                    consignmentId: "@consignmentId",
                    functionClassificationValueId: "@functionClassificationValueId",
                    versionChangeReason: "@versionChangeReason",
                    CreateClearingInstructionOnly: "@CreateClearingInstructionOnly",
                    GoodsDeclarationType : '@GoodsDeclarationType'
                });
            }

            getGoodsDeclarations(): ng.resource.IResourceClass<interfaces.customs.IGoodsDeclarationDisplay> {
                return this.$resource<interfaces.customs.IGoodsDeclarationDisplay>(this.ENV.DSP_URL + "GoodsDeclaration/GetGoodsDeclarations", {
                    consignmentId: "@consignmentId",
                    version: "@version",
                    getAll: "@getAll",
                    isClearingInstruction: "@isClearingInstruction",
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            delete(gsdId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/Delete", {
                    gsdId: gsdId
                });
            }

            deleteInvoice(invoiceId: number, goodsDecId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/DeleteInvoice", {
                    invoiceId: invoiceId,
                    goodsDecId: goodsDecId
                });
            }

            removeGoodsDeclarationValuation(dvlId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RemoveGoodsDeclarationValuation", {
                    dvlId: dvlId
                });
            }

            getGoodsDeclaration(): ng.resource.IResourceClass<interfaces.customs.IGoodsDeclaration> {
                return this.$resource<interfaces.customs.IGoodsDeclaration>(this.ENV.DSP_URL + "GoodsDeclaration/GetGoodsDeclaration", {
                    goodsDecId: "@goodsDecId",
                    version: "@version"
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getEDIFactMessageDetail(): ng.resource.IResourceClass<interfaces.integration.IEDIFactMessage> {
                return this.$resource<interfaces.integration.IEDIFactMessage>(this.ENV.DSP_URL + "GoodsDeclaration/GetEDIFactMessageDetail", {
                    mesId: "@mesId"                    
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            saveAndValidateEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/SaveAndValidateEDI");
            }

            validateAndSendEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ValidateandSendEDI");
            }

            requestEDIResponseResend(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RequestEDIResponseResend", {
                    ownerEntityId: "@ownerEntityId",
                    goodsDecId: "@goodsDecId"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/Save");
            }

            recalculateDuties(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RecalculateDuties");
            }

            changeCPCCode(goodsDeclarationId: number, procedureCategoryId: number, customsProcedureId?: number, prevCustomsProcedureId?: number, procedureMeasureCode?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ChangeCPCCode", {
                    goodsDeclarationId: goodsDeclarationId,
                    procedureCategoryId: procedureCategoryId,
                    customsProcedureId: customsProcedureId,
                    prevCustomsProcedureId: prevCustomsProcedureId,
                    procedureMeasureCode: procedureMeasureCode,
                });
            }

            submitDefaultCaseDocuments(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/SubmitDefaultCaseDocuments", {
                    goodsDeclarationId: goodsDeclarationId
                });
            }

            createManualDeclaration(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/CreateManualDeclaration");
            }            

            generateFromInvoice(goodsDecId:number, version:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/GenerateFromInvoice", {
                    goodsDecId: goodsDecId,
                    version: version
                });
            }

            changeCustomsEDIStatus(goodsDeclarationId: number, ediStatusId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ChangeCustomsEDIStatus", {
                    goodsDeclarationId: goodsDeclarationId,
                    ediStatusId: ediStatusId
                });
            }

            recalculateTotals(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RecalculateTotals", {
                    goodsDeclarationId: goodsDeclarationId                    
                });
            }           
            
            getGoodsDeclarationInvoices(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "GoodsDeclaration/GetGoodsDeclarationInvoices", {
                    goodsDeclarationId: goodsDeclarationId,
                });
            }

            getCustomsValuationTypes(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "GoodsDeclaration/GetCustomsValuationTypes", {
                    countryId: countryId,
                });
            }            

            recalculateValuation(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RecalculateValuation", {
                    goodsDeclarationId: goodsDeclarationId                    
                });
            }     

            proRataValuationOverInvoices(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ProRataValuationOverInvoices", {
                    goodsDeclarationId: goodsDeclarationId                    
                });
            }     

            calculateCustomsFactor(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/CalculateCustomsFactor", {
                    goodsDeclarationId: goodsDeclarationId                    
                });
            }          
            
            updateReceiptNumberAndDate(goodsDeclarationId: number, receiptNo:string, receiptDate:string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/UpdateReceiptNumberAndDate", {
                    goodsDeclarationId: goodsDeclarationId,
                    receiptNo: receiptNo,
                    receiptDate: receiptDate             
                });
            }          
            
            updateExpiryDate(goodsDeclarationId: number, expiryDate:string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/UpdateExpiryDate", {
                    goodsDeclarationId: goodsDeclarationId,
                    expiryDate: expiryDate             
                });
            }    
            
            manuallyCloseGoodsDeclaration(goodsDeclarationId: number, comments: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ManuallyCloseGoodsDeclaration", {
                    goodsDeclarationId: goodsDeclarationId,
                    comments: comments             
                });
            }                 

            getCustomsRiskManagementGoodsDeclarationViewModel(): ng.resource.IResourceClass<interfaces.customs.CustomsRiskManagementGoodsDeclarationViewModel> {
                return this.$resource<interfaces.customs.CustomsRiskManagementGoodsDeclarationViewModel>(this.ENV.DSP_URL + "GoodsDeclaration/GetCustomsRiskManagementGoodsDeclarationViewModel", {
                    connectSearch: '@connectSearch',
                    declarationCountryId: '@declarationCountryId',                
                    showAll : '@showAll',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }  

            releaseCreditHold(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ReleaseCreditHold");
            }
            
            updateExpiryDates(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/UpdateExpiryDates");
            }  

            updateChangeReason(goodsDeclarationId: number, changeReasonComments: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/UpdateChangeReason", {
                    goodsDeclarationId: goodsDeclarationId,
                    changeReasonComments: changeReasonComments             
                });
            }         
            
            manuallyCloseGoodsDeclarations(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ManuallyCloseGoodsDeclarations");
            }                

            getCustomsRiskManagementGoodsDeclarationViewModelExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'GoodsDeclaration/GetCustomsRiskManagementGoodsDeclarationViewModelExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));
                url += '&declarationCountryId=' + params.declarationCountryId;
                url += '&showAll=' + params.showAll;

           
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }            
            



        }
    }
    angular.module("app").service("goodsDeclarationService", services.customs.goodsDeclarationService);
}